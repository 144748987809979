import React from "react"
import classNames from "classnames"
import VisualNavigationLink from "./VisualNavigationLink"
import "./VisualNavigation.scss"

const VisualNavigation = ({
  items,
  genericImages,
  classes
}) => {

  return (
    <div className={classNames("section visual-navigation-section container-fluid", classes)}>
      <div className="row">
        {items && items.node && items.node.items.map((item, index) => {
            const slug = item.object_slug
            const {target} = item
            const {url} = item
            const headline = item.title

            return <VisualNavigationLink key={slug} slug={slug} target={target} url={url} title={headline} genericImages={genericImages} index={index} />
          })}
        { items && items.edges && items.edges && items.edges.map((item, index) => {
            const {slug} = item.node
            const headline = item.node.title

            return <VisualNavigationLink key={slug} slug={`research-practice/${ slug }`} title={headline} genericImages={genericImages} index={index} />
          })}
      </div>
    </div>
  )
}

export default VisualNavigation
